
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.content {
  flex-grow: 1; /* Take the available space */
  /* Optional: Add padding or margin for content if needed */
  transform: scale(0.8); /* Adjust the scale value as needed */
  transform-origin: top left; /* Optional: Change the origin point of the scaling */
  width: calc(100% / 0.8); /* Adjust width to counteract the scaling */
  height: calc(100% / 0.8); /* Adjust height if necessary */
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding:10px;
  text-align: center;
  gap: 5px;
  font-size: smaller;
  border-top: 0.5px solid rgb(182, 182, 182);
}

