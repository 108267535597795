nav {
  background-color: #333;
  display: flex;
  flex-wrap: wrap;
  position: sticky; /* Make navbar sticky */
  top: 0px; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure navbar is above other content */
  width: 100%; /* Ensure navbar spans the full width */
  margin: 0; /* Ensure no margin collapse */
}

nav a {
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  min-width: 150px; /* Example minimum width for nav items */
}

nav a:hover {
  background-color: #ddd;
  color: black;
}

nav a.active {
  background-color: #1976d2;
  color: white;
}

.button {
  align-items: center;
  color: #333;
  text-align: center;
  text-decoration: none;
  background-color: #ddd;
  border: none;
  min-width: 80px;
}

.button:hover {
  background-color: #3b87d4;
}

.icon {
  transition: transform 0.2s ease-in-out;
}

.icon:hover {
  animation: bounce 0.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); /* Initial and final position */
  }
  40% {
    transform: translateY(-10px); /* Bounce up */
  }
  60% {
    transform: translateY(-5px); /* Bounce down */
  }
}
